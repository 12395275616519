import React from 'react'
import WithdrawFunds from '../../withdrawfunds/WithdrawFunds'

const Withdraw = () => {
  return (
    <>
      <WithdrawFunds />
    </>
  )
}

export default Withdraw
