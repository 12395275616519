import React from 'react'
import styles from './invest.module.css'

const Invest = () => {
  return (
    <div className={styles.invest_container}>
      Invest here
    </div>
  )
}

export default Invest
