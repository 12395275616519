import React from 'react'
import { Container } from 'react-bootstrap'
// import styles from './paymentbyusdt.module.css'

const PaymentByUsdt = () => {
  return (
    <section>
        <Container>

        </Container>
    </section>
  )
}

export default PaymentByUsdt
