import React from 'react'
import Orders from '../../dashboardpage/orders/Orders'

const TransactionHistory = () => {
  return (
    <div>
      <Orders />
    </div>
  )
}

export default TransactionHistory
