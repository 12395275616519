import React from 'react'


const Recipient = ({setActiveTabSend}) => {

  return (
    <div>
      Hello
    </div>
  )
}

export default Recipient
